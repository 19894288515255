import type {SeoItem} from "~/types/seo/SeoItem";
import type {FaqItem} from "~/types/seo/FaqItem";

export const siteConfig = () => useRuntimeConfig().public

export const getMeta = (seo?: SeoItem) => {
    if (!seo) return;

    const config = siteConfig();
    const hasMedia = seo.media && seo.media.length > 0;
    const ogImage = hasMedia ? `${config.site_url}/image/${seo.media[0].file_url}` : "/logo.png";
    const ogImageAlt = hasMedia ? seo.media[0].alt_text : seo.title;

    useSeoMeta({
        title: seo.title,
        description: seo.description || config.site_persian_name,
        robots: seo.robots?.code === 1 ? "index, follow" : "noindex, nofollow",
        publisher: config.site_persian_name,
        applicationName: config.site_name,
        mobileWebAppCapable: "yes",
        appleMobileWebAppCapable: "yes",
        appleMobileWebAppTitle: config.site_persian_name,
        ogSiteName: config.site_persian_name,
        ogUrl: config.site_url,
        ogType: 'website',
        ogImage,
        ogImageAlt,
        ogImageHeight: "630",
        ogImageWidth: "1200",
        ogImageType: "image/png",
        ogLocale: "fa_IR",
        ogTitle: seo.title,
        ogDescription: seo.description || config.site_persian_name,
        colorScheme: 'only light',
        themeColor: '#0000FFFF',
        twitterTitle: seo.title,
        twitterImage: ogImage,
        twitterImageAlt: ogImageAlt,
    });
    // useHead({
    //     link: [
    //         {
    //             rel: 'canonical',
    //             href: `${config.site_url}/${seo.canonical}`,
    //         },
    //     ],
    // });
};

export const makeFaqs = (faqs: FaqItem[]) => (faqs.map((q) => {
        return {
            label: q.question,
            content: q.answer
        }
    })
)

export const setBreadcrumbSchema = (items: { name: string, item: string }[]) => (
    defineBreadcrumb({
        itemListElement: items
    })
)
export const setWebPageSchema = (title: string, hasFaq = false) => (
    defineWebPage({
        '@type': hasFaq ? 'FAQPage' : 'WebPage',
        name: title
    })
)

export const setFaqSchema = (faqs: FaqItem[]) => {
    if (!faqs || !faqs.length) return;
    return [
        ...faqs.map((faq) => defineQuestion({
            name: faq.question,
            acceptedAnswer: faq.answer,
        }))
    ]
};

export const getOrganizationSchema = () => {
    const config = useRuntimeConfig().public;
    return defineOrganization({
        name: config.site_persian_name,
        url: config.site_url,
        logo: `${config.site_url}/logo.png`,
        sameAs: [
            "https://www.instagram.com/example",
            "https://www.twitter.com/example"
        ],
        serviceType: [
            "رزرو آنلاین تور",
            "رزرو آنلاین پرواز داخلی و خارجی",
            "رزرو آنلاین هتل",
            "خرید بلیط اتوبوس",
            "رزرو بلیط قطار"
        ]
    });
};

export const getHotelSchema = (hotel: { name: string, url: string, address: string, phone: string, rating: number, price: number }) => {
    return defineLocalBusiness({
        name: hotel.name,
        url: hotel.url,
        telephone: hotel.phone,
        address: {
            '@type': 'PostalAddress',
            streetAddress: '',
            addressLocality:'',
            addressRegion: '',
            postalCode: '',
            addressCountry: 'IR',
        },
        aggregateRating: ref({
            '@type': 'AggregateRating',
            ratingValue: hotel.rating,
            reviewCount: 120 // تعداد نظرات واقعی را از API دریافت کن
        }),
        priceRange: `$${hotel.price} per night`,
        localBusinessType: "Hotel" // اضافه کردن نوع هتل برای `SEO`
    });
};

export const getFlightSchema = (flight: { airline: string, from: string, to: string, price: number }) => {
    return defineProduct({
        name: `${flight.airline} Flight from ${flight.from} to ${flight.to}`,
        offers: {
            '@type': 'Offer',
            price: flight.price,
            priceCurrency: "USD",
            availability: "https://schema.org/InStock",
            seller: {
                '@type': 'Organization',
                name: flight.airline
            }
        }
    });
};

export const getSearchActionSchema = () => {
    const config = useRuntimeConfig().public;
    return defineSearchAction({
        target: `${config.site_url}/search?q={search_term_string}`,
        queryInput: "required name=search_term_string"
    });
};

export const getOfferSchema = (offer: { name: string, price: number, url: string }) => {
    return defineOffer({
        name: offer.name,
        price: `$${offer.price}`,
        url: offer.url
    });
};
export const getLocalBusinessSchema = (business: { name: string, url: string, address: string, phone: string, rating: number }) => {
    return defineLocalBusiness({
        name: business.name,
        url: business.url,
        telephone: business.phone,
        address: {
            '@type': 'PostalAddress',
            streetAddress: '',
            addressLocality:'',
            addressRegion: '',
            postalCode: '',
            addressCountry: 'IR',
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: business.rating,
            reviewCount: 120 // تعداد نظرها رو میشه از API گرفت
        }
    });
};

/**
 * مقداردهی `Product` برای پروازها و خدمات گردشگری
 */
export const getProductSchema = (product: { name: string, price: number, url: string, brand: string }) => {
    return defineProduct({
        name: product.name,
        offers: {
            '@type': 'Offer',
            price: product.price,
            priceCurrency: "USD",
            availability: "https://schema.org/InStock",
            seller: {
                '@type': 'Organization',
                name: product.brand
            }
        },
        url: product.url
    });
};

/**
 * مقداردهی `Event` برای رویدادهای گردشگری
 */
export const getEventSchema = (event: { name: string, startDate: string, location: string, url: string }) => {
    return defineEvent({
        name: event.name,
        startDate: event.startDate,
        address: {
            '@type': 'Place',
            streetAddress: '',
            addressLocality:'',
            addressRegion: '',
            postalCode: '',
            addressCountry: 'IR',
        },
        url: event.url
    });
};

/**
 * مقداردهی `Review` برای نظرات کاربران درباره هتل‌ها و پروازها
 */
export const getReviewSchema = (review: { author: string, rating: number, reviewBody: string }) => {
    return defineReview({
        author: {
            '@type': 'Person',
            name: review.author
        },
        reviewRating: {
            '@type': 'Rating',
            ratingValue: review.rating,
            bestRating: 5
        },
        reviewBody: review.reviewBody
    });
};

/**
 * مقداردهی `JobPosting` برای آگهی‌های استخدام در آژانس‌ها
 */
// export const getJobPostingSchema = (job: {
//     title: string,
//     company: string,
//     location: { street: string, locality: string, region: string, postalCode: string, country: string },
//     salary: string,
//     description: string,
//     datePosted: string,
//     validThrough: string,
//     employmentType: string
// }) => {
//     return defineJobPosting({
//         title: job.title,
//         description: job.description,
//         datePosted: job.datePosted,
//         validThrough: job.validThrough,
//         employmentType: job.employmentType,
//         hiringOrganization: {
//             '@type': 'Organization',
//             name: job.company
//         },
//         jobLocation: {
//             '@type': 'Place',
//             address: {
//                 '@type': 'PostalAddress',
//                 streetAddress: job.location.street,
//                 addressLocality: job.location.locality,
//                 addressRegion: job.location.region,
//                 postalCode: job.location.postalCode,
//                 addressCountry: job.location.country
//             }
//         },
//         baseSalary: {
//             '@type': 'MonetaryAmount',
//             currency: "USD",
//             value: job.salary
//         }
//     });
// };


/**
 * مقداردهی `Video` برای صفحه‌هایی که ویدیو دارند
 */
export const getVideoSchema = (video: { name: string, description: string, uploadDate: string, thumbnailUrl: string }) => {
    return defineVideo({
        name: video.name,
        description: video.description,
        uploadDate: video.uploadDate,
        thumbnailUrl: video.thumbnailUrl
    });
};

/**
 * مقداردهی `SoftwareApplication` برای سیستم‌های رزرواسیون آنلاین
 */
// export const getSoftwareAppSchema = (app: { name: string, operatingSystem: string, category: string }) => {
//     return defineSoftwareApp({
//         name: app.name,
//         operatingSystem: app.operatingSystem,
//         applicationCategory: app.category
//     });
// };


export const getImageSchema = (image: { url: string; contentUrl?: string; caption?: string; language?: string }) => {
    return defineImage({
        url: image.url,
        contentUrl: image.contentUrl || image.url, // اگر `contentUrl` نداشت، `url` رو بذار
        caption: image.caption || "Default caption",
        inLanguage: image.language || "fa"
    });
};
export const getGallerySchema = (images: { url: string; caption?: string }[], siteUrl: string) => {
    if (!images.length) return null;

    return defineItemList({
        name: "Gallery",
        itemListElement: images.map((image, index) =>
            getImageSchema({
                url: `${siteUrl}/image/${image.url}`,
                contentUrl: `${siteUrl}/gallery/${image.url}`,
                caption: image.caption || `Image ${index + 1}`,
                language: "fa"
            })
        )
    });
};

export const setWebsiteSchema = () => {
    return defineWebSite({
        name: siteConfig().site_persian_name,
        description: "جامع ترین موتور جستجوی سفر – رزرو پرواز، هتل، تور، قطار و اتوبوس",
        url: siteConfig().site_url,
        image: [
            `${siteConfig().site_url}/logo.png`
        ],
        inLanguage: "FA",
        publisher: [
            siteConfig().site_name
        ],
    })
}
